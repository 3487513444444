import {marked} from "marked";
import {OverridableStringUnion} from "@mui/types";
import {Variant} from "@mui/material/styles/createTypography";
import {TypographyPropsVariantOverrides} from "@mui/material/Typography/Typography";

export const renderText = (text?: string) => {
  const sanitized = text?.replaceAll('#', '') || '';

  // @ts-ignore
  return sanitized?.split('\n').reduce((children: any, textSegment: string, index: number) => {
    return [...children, index > 0 && <br key={index}/>, textSegment];
  });
}

export const getVariant = (text?: string): OverridableStringUnion<Variant | 'inherit', TypographyPropsVariantOverrides> => {
  const numOfPounds = text?.split('#')?.length;
  if(numOfPounds){
    // @ts-ignore
    return `h${numOfPounds - 1}`;
  }

  return 'inherit'
}