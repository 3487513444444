import {Typography, styled, Grid, Container, Button} from "@mui/material";
import {FC} from "react";
import {Img} from "./cardContent/Img";
import {useScreenSize} from "../hooks/useScreenSize";
import {useGraphQLPost} from "../hooks/useGraphQLPost";
import {Page} from "./pages/buy/model";
import {renderText} from "../utils/utils";

const Div = styled('div')(({theme}) => ({
  padding: theme.spacing(2),
  marginTop: '5%',
}));


const StyledTypography = styled(Typography)({
  textAlign: 'justify'
});

const Logo = styled('img')(({theme}) => ({
  width: '75%',
  paddingLeft: theme.spacing(2)
}))

const query = `{
  homePageCollection{
    items {
      description,
    }
  }
}`;

export const MainLayout: FC = () => {
  const {isPastThreshold: showLogo} = useScreenSize();
  const {data} = useGraphQLPost<Page>('homePageCollection', query);

  return (
    <Container>
      <Div>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            <StyledTypography paragraph>
              {renderText(data?.description)}
            </StyledTypography>
          </Grid>
          {showLogo && (
            <Grid item sm={4}>
              <Logo src="/images/HHH-logo_sm.png" alt="Hiking Hops and Homes"/>
            </Grid>
          )}
          {/* TODO */}
          {/*<Grid item sm={2}/>*/}
          {/*<Grid item sm={4} xs={12} >*/}
          {/*  <Button color="primary" variant="contained" sx={{textAlign: 'center'}}>Find My Dream Home</Button>*/}
          {/*</Grid>*/}
        </Grid>
      </Div>

      <Grid container spacing={3} sx={{marginBottom: theme => theme.spacing(2)}}>
        <Grid item xs={12} sm={4}>
          <Img type="buy"/>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Img type="sell"/>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Img type="learn"/>
        </Grid>
      </Grid>
    </Container>
  )
}