import {useCallback, useEffect, useState} from "react";
import axios from "axios";

const token = process.env.REACT_APP_CONTENTFUL_PREVIEW_ACCESS_TOKEN || process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN;

const HEADERS = {
  "Content-Type": "application/json",
  Authorization: `Bearer ${token}`,
}

export const useGraphQLPost = <T extends any>(key: string, query?: string): any => {
  const [data, setData] = useState<T>();
  const [isPending, setIsPending] = useState(true);

  const fetch = useCallback(() => {
    return axios.post(`https://graphql.contentful.com/content/v1/spaces/${process.env.REACT_APP_CONTENTFUL_SPACE_ID}`, JSON.stringify({query}), {headers: HEADERS})
  }, [query]);

  useEffect(() => {
    setIsPending(true);
    fetch()
      .then(response => {
        setData(response.data?.data[key]?.items[0]);
        setIsPending(false);
      }).catch(() => {
      setIsPending(false);
    })
  }, [key, fetch])

  return {data, isPending}
}