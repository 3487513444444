import { createTheme } from "@mui/material";

export const BANNER_COLOR = '#6e312c';

export const THEME = createTheme({
  palette: {
    primary: {
      main: '#3f1717',
      light: '#6e312c'
    }
  },
  typography: {
    fontFamily: [
      'acumin-pro,sans-serif',
    ].join(',')
  }
})