import { Typography } from "@mui/material";
import { styled } from "@mui/system";
import { FC } from "react";
import {useScreenSize} from "../../../hooks/useScreenSize";

const Div = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.primary.light,
  padding: theme.spacing(2),
  marginTop: theme.spacing(1),
  textAlign: 'center'
}));


const StyledTypography = styled(Typography)({
  color: 'white'
});

export const Banner: FC = () => {
  const {isPastThreshold} = useScreenSize();

  return (
    <Div>
      <StyledTypography variant={isPastThreshold ? 'h1' : 'h4'}>Hiking, Hops, and Homes</StyledTypography>
    </Div>
  )
}