import {FC, useEffect} from "react";
import {Button, Container, Grid, styled, Typography} from "@mui/material";
import {useGraphQLPost} from "../../../hooks/useGraphQLPost";
import {Page} from "./model";
import {renderText} from "../../../utils/utils";
import {useScreenSize} from "../../../hooks/useScreenSize";

const StyledTypography = styled(Typography)({
  textAlign: 'justify'
});

const Div = styled('div')(({theme}) => ({
  padding: theme.spacing(2),
  marginTop: '5%'
}));

const Img = styled('img')`
  width: 100%
`;

const query = `{
  sellPageCollection{
    items {
      subtitle,
      description,
      description2,
    }
  }
}`;


export const Layout: FC = () => {
  const {data} = useGraphQLPost<Page>('sellPageCollection', query);
  const { isPastThreshold } = useScreenSize();

  const padding = isPastThreshold ? '25%' : 'auto';

  const handleOnButtonClick = () => {
    // TODO, not working in mobile
    // window.open('https://www.instagram.com/jturbesrealestate/channel/?hl=en');
    window.open('https://www.instagram.com/jturbesrealestate/?hl=en');
  };

  return (
    <Container>
      <Div>
        <Grid container spacing={2}>
          <Grid item md={12} sx={{textAlign: 'center'}}>
            <Button onClick={handleOnButtonClick} color="primary" variant="contained" sx={{textAlign: 'center'}}>
              The Colorado Springs Housing Market
            </Button>
          </Grid>
          <StyledTypography
            paragraph
            sx={{paddingLeft: padding, paddingRight: padding, textAlign: 'center', marginTop: '2%', marginBottom: '2%'}}>
            {renderText(data?.description)}
          </StyledTypography>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Img src="/images/sellDesc2.jpg" alt="sell"/>
          </Grid>
          <Grid item xs={12} md={6} alignSelf="center">
            <StyledTypography variant="h6">
              {renderText(data?.description2)}
            </StyledTypography>
          </Grid>
        </Grid>
      </Div>
    </Container>
  )
}