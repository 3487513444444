import {FC, useEffect} from "react";
import {Container, Grid, styled, Typography} from "@mui/material";
import {useGraphQLPost} from "../../../hooks/useGraphQLPost";
import {Page} from "./model";
import {renderText} from "../../../utils/utils";

const StyledTypography = styled(Typography)({
  textAlign: 'justify'
});

const Div = styled('div')(({theme}) => ({
  padding: theme.spacing(2),
  marginTop: '5%'
}));

const Img = styled('img')`
  width: 100% 
`;

const query = `{
  buyPageCollection{
    items {
      subtitle,
      description,
    }
  }
}`;


export const Layout: FC = () => {
  const {data} = useGraphQLPost<Page>('buyPageCollection', query);

  return (
    <Container>
      <Div>
        <StyledTypography paragraph>
          {renderText(data?.subtitle)}
        </StyledTypography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Img src="/images/chair.jpg" alt="chair"/>
          </Grid>
          <Grid item xs={12} md={6}>
            <StyledTypography paragraph>
              {renderText(data?.description)}
            </StyledTypography>
          </Grid>
        </Grid>
      </Div>
    </Container>
  )
}