import {FC} from "react";
import {useGraphQLPost} from "../../hooks/useGraphQLPost";
import {BannerSchema} from "../pages/buy/model";
import {Div, Img, StyledTypography} from "./styles";
import {Grid} from "@mui/material";
import {getVariant, renderText} from "../../utils/utils";
import {useScreenSize} from "../../hooks/useScreenSize";

type PageType = 'home' | 'buy' | 'learn' | 'sell';

type Props = {
  page: PageType;
}

const COLLECTION_KEY: Record<PageType, string> = {
  home: 'homePageCollection',
  buy: 'buyPageCollection',
  learn: 'learnPageCollection',
  sell: 'sellPageCollection'
}


export const Banner: FC<Props> = props => {
  const {page} = props;
  const collectionKey = COLLECTION_KEY[page];
  const {isPastThreshold} = useScreenSize();

  const query = `{
    ${collectionKey}{
      items {
        banner,
        bannerPhoto {
          url
        }
      }
    }
  }`;

  const {data} = useGraphQLPost<BannerSchema>(collectionKey, query);

  const variant = getVariant(data?.banner);

  return (
    <Div>
      {data?.bannerPhoto?.url
        ? (
          <Grid container spacing={2} justifyContent="center" alignItems="center">
            {isPastThreshold && (
              <Grid item xs={4}>
                <Img src={data.bannerPhoto.url} alt="banner"/>
              </Grid>
            )}
            <Grid item xs={8}>
              <StyledTypography paragraph variant={variant}>
                {renderText(data.banner)}
              </StyledTypography>
            </Grid>
          </Grid>
        )
        : (
          <StyledTypography paragraph variant={variant}>{renderText(data?.banner)}</StyledTypography>
        )}
    </Div>
  )
}
