import {styled, Typography} from "@mui/material";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";

export const HeaderText = styled(Typography)({
  color: 'black',
  fontFamily: 'lato, sans-serif',
  fontWeight: 700,
  fontStyle: 'normal'
});

export const BrandIcons = styled(FontAwesomeIcon)(({theme}) => ({
  color: theme.palette.primary.main,
  fontSize: 'xx-large'
}));

export const StyledLink = styled(Link)({
  textDecoration: 'none',
  color: 'black'
});
