import {useCallback, useEffect, useState} from "react";

export const useScreenSize = (minScreenWidth: number = 500) => {
    const [isPastThreshold, setIsPastThreshold] = useState(window.screen.width > minScreenWidth);

    const shouldShowLogo = useCallback(() => {
        setIsPastThreshold(window.innerWidth > minScreenWidth);
    }, [])

    useEffect(() => {
        window.addEventListener('resize', shouldShowLogo);

        return () => window.removeEventListener('resize', shouldShowLogo);
    }, [shouldShowLogo])

    return {
        isPastThreshold
    }
}