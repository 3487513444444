import {CoverPhoto} from "../../CoverPhoto";
import {Banner} from "./Banner";
import {MainLayout} from "../../MainLayout";
import React from "react";

export const MainPage = () => {
  return (
    <>
      <CoverPhoto page="home" />
      <Banner/>
      <MainLayout/>
    </>
  )

}