import React from "react";

import {CoverPhoto} from "../../CoverPhoto";
import {Banner} from "../../banner/Banner";


export const LearnPage = () => {
  return (
    <>
      <CoverPhoto page="learn" />
      <Banner page="learn" />
    </>
  )

}