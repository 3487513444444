import {faFacebookSquare, faInstagramSquare, IconDefinition} from "@fortawesome/free-brands-svg-icons";
import {
  AppBar,
  Container,
  Toolbar,
  IconButton,
  Button,
  Box,
} from "@mui/material";
import {FC, useState} from "react";
import {useNavigate} from "react-router-dom";
import {BrandIcons, HeaderText, StyledLink} from "./styles";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars} from "@fortawesome/free-solid-svg-icons";
import {HeaderDrawer} from "./HeaderDrawer";
import {useScreenSize} from "../../hooks/useScreenSize";
import {Page, PAGES} from "../../pages";

export type BrandIconType = {
  id: string;
  icon: IconDefinition;
  link: string;
}

const BRAND_ICONS: BrandIconType[] = [
  {
    id: 'facebook',
    icon: faFacebookSquare,
    link: 'https://www.facebook.com/jturbesrealestate'
  },
  {
    id: 'instagram',
    icon: faInstagramSquare,
    link: 'https://www.instagram.com/jturbesrealestate/?hl=en'
  }
]

export const Header: FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const {isPastThreshold} = useScreenSize();

  const navigate = useNavigate();

  const handleOnLinkClick = (link: string) => {
    window.open(link);
  }

  const handleOnButtonClicked = (page: Page) => {
    navigate(page.path);
  }

  const HeaderLink = () => <StyledLink to="/">Jenessa Turbes Real Estate</StyledLink>;

  return (
    <AppBar position="static" sx={{backgroundColor: 'inherit'}}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <HeaderDrawer
            pages={[
              {
                name: 'Home',
                path: '/'
              },
              ...PAGES
            ]}
            brandIcons={BRAND_ICONS}
            isOpen={isOpen}
            onToggle={() => setIsOpen(!isOpen)}
          />
          <IconButton
            size="large"
            edge="start"
            color="primary"
            aria-label="open drawer"
            onClick={() => setIsOpen(!isOpen)}
            sx={{mr: 2, display: {md: 'none'}}}
          >
            <FontAwesomeIcon icon={faBars}/>
          </IconButton>

          <HeaderText
            variant="h6"
            noWrap
            // @ts-ignore
            component="div"
            sx={{mr: 2, display: {xs: 'none', md: 'flex'}}}
          >
            <HeaderLink/>
          </HeaderText>

          <HeaderText
            variant="h6"
            noWrap
            // @ts-ignore
            component="div"
            sx={{flexGrow: 1, display: {sm: 'flex', md: 'none'}}}
          >
            <HeaderLink/>
          </HeaderText>
          {isPastThreshold && (
            <Box sx={{flexGrow: 1, display: {md: 'flex', justifyContent: 'end', textAlign: 'end'}}}>
              {PAGES.map((page) => (
                <Button
                  key={page.name}
                  onClick={() => handleOnButtonClicked(page)}
                  sx={{my: 2, color: 'black', display: 'block'}}
                >
                  {page.name}
                </Button>
              ))}
              {BRAND_ICONS.map(brandIcon => (
                <IconButton key={brandIcon.id}>
                  <BrandIcons
                    icon={brandIcon.icon}
                    onClick={() => handleOnLinkClick(brandIcon.link)}/>
                </IconButton>
              ))}
            </Box>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  )
}