import React from 'react';
import {Header} from './components/header/Header';
import {ThemeProvider} from '@mui/material';
import {THEME} from './theme/Theme';
import {BrowserRouter} from 'react-router-dom';
import {Route, Routes} from "react-router";
import {MainPage} from "./components/pages/main/MainPage";
import {PAGES} from "./pages";


function App() {
  return (
    <div className="App">
      <ThemeProvider theme={THEME}>
        <BrowserRouter basename="/">
          <Header/>
          <Routes>
            <Route path="/" element={<MainPage />}/>
            {PAGES.map(page => <Route key={page.name} path={page.path} element={page.component}/>)}
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </div>
  );
}

export default App;
