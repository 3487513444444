import {styled} from "@mui/system";
import {Typography} from "@mui/material";

export const Div = styled('div')(({theme}) => ({
  backgroundColor: theme.palette.primary.light,
  padding: theme.spacing(2),
  marginTop: theme.spacing(-1),
  textAlign: 'center'
}));


export const StyledTypography = styled(Typography)({
  color: 'white'
});

export const Img = styled('img')`
  width: 100% 
`;