import React from "react";

import {CoverPhoto} from "../../CoverPhoto";
import {Layout} from "./Layout";
import {Banner} from "../../banner/Banner";


export const SellPage = () => {
  return (
    <>
      <CoverPhoto page="sell" text="Interested In Selling Your Home?" />
      <Banner page="sell" />
      <Layout/>
    </>
  )

}