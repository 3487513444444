import {FC} from "react";
import {styled} from "@mui/system"
import {useGraphQLPost} from "../hooks/useGraphQLPost";
import {CoverPhotoType} from "./pages/buy/model";
import {Typography} from "@mui/material";
import {useScreenSize} from "../hooks/useScreenSize";

const Img = styled('img')({
  width: '100%'
});

const Div = styled('div')({
  position: 'relative'
});

const CoverText = styled(Typography)({
  position: 'absolute',
  bottom: '8px',
  width: '100%'
});

type Props = {
  page: 'home' | 'buy' | 'learn' | 'sell',
  text?: string;
}

const IMG_SRC: Record<string, string> = {
  home: '/images/home-page.jpg',
  buy: '/images/model-home-1.jpg',
  sell: '/images/sell-cover.jpg',
} || undefined;

export const CoverPhoto: FC<Props> = props => {
  const {page, text} = props;

  const query = `{
    ${page}PageCollection{
      items {
        banner,
        bannerPhoto {
          url
        },
        coverPhoto {
          url
        } 
      }
    }
  }`;

  const {data, isPending} = useGraphQLPost<CoverPhotoType>(`${page}PageCollection`, query);
  const {isPastThreshold} = useScreenSize();

  return (
    <Div>
      <Img src={IMG_SRC[page] || data?.coverPhoto?.url} alt="cover" />
      {(text && !isPending) && <CoverText variant={isPastThreshold ? 'h2' : 'h5'} align="center" >{text}</CoverText>}
    </Div>
  )
}