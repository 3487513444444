import {Card, styled} from "@mui/material";
import {FC} from "react";

const StyledImg = styled('img')({
    width: '100%'
})

const StyledCard = styled(Card)(({theme}) => ({
    border: '2px solid black',
    paddingBottom: theme.spacing(2)
}))

type Props = {
    type: 'buy' | 'sell' | 'learn'
}

export const Img: FC<Props> = ({type}) => {

    const SOURCE = {
        buy: '/images/buy.png',
        sell: '/images/sell.png',
        learn: '/images/learn.png'
    }

    return (
        <StyledCard>
            <StyledImg src={SOURCE[type]} />
        </StyledCard>
    )
}