import {Box, Divider, Drawer, IconButton, List, ListItemButton, ListItemText} from "@mui/material";
import {BrandIconType} from "./Header";
import {FC} from "react";
import {BrandIcons} from "./styles";
import {useNavigate} from "react-router-dom";
import {Page, PAGES} from "../../pages";

type Props = {
  pages: Page[];
  brandIcons?: BrandIconType[];
  isOpen: boolean;
  onToggle(): void;
}

export const HeaderDrawer: FC<Props> = ({pages, brandIcons, isOpen, onToggle}) => {
  const navigate = useNavigate();

  const handleOnLinkClick = (link: string) => {
    window.open(link);
  }

  const list = () => (
    <Box
      sx={{width: '50vw'}}
      role="presentation"
      onClick={() => onToggle()}
    >
      <List>
        {PAGES.map((page, index) => (
          <ListItemButton key={page.name} onClick={() => navigate(page.path)}>
            <ListItemText primary={page.name}/>
          </ListItemButton>
        ))}
      </List>

      <Divider/>

      <Box sx={{flexGrow: 1, display: {sm: 'flex', justifyContent: 'start', textAlign: 'start'}}}>
        {brandIcons?.map(brandIcon => (
          <IconButton key={brandIcon.id}>
            <BrandIcons
              icon={brandIcon.icon}
              onClick={() => handleOnLinkClick(brandIcon.link)}/>
          </IconButton>
        ))}
      </Box>
    </Box>
  );

  return (
    <div>
      <Drawer
        anchor="left"
        open={isOpen}
        onClose={() => onToggle()}
      >
        {list()}
      </Drawer>
    </div>
  );
}