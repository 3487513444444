import {ReactNode} from "react";
import {BuyPage} from "./components/pages/buy/BuyPage";
import {LearnPage} from "./components/pages/learn/LearnPage";
import {SellPage} from "./components/pages/sell/SellPage";

export type Page = {
  name: string;
  path: string;
  component?: ReactNode;
}

export const PAGES: Page[] = [
  {
    name: 'Buy',
    path: '/buy',
    component: <BuyPage />
  },
  {
    name: 'Learn',
    path: '/learn',
    component: <LearnPage />
  },
  {
    name: 'Sell',
    path: '/sell',
    component: <SellPage />
  }
]
